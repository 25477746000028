<template>
  <Icon fill="#00CAD9" class='icon'>
    <rect width="26" height="24" rx="2" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.59 13.76c-.5-.45-1.28-1.28-1.8-1.8-.53-.49-.87-.08-1.5.6-.71.71 0 1.09 1.01 2.1l1.84 1.84c.67.6 1.23-.3 2.13-1.17l3.67-3.7c1.17-1.13 1.43-1.13.5-2.03-.83-.87-.8-.87-2.1.48-.6.57-3.57 3.57-3.75 3.68z" fill="#fff" />
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'

export default {
  name: 'FilledCheckIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 26px;
  height: 24px;
}
</style>
