<template>
  <Icon class='icon' viewBox="0 0 18 19">
    <path d="M3.22 3.89a.5.5 0 01.51-.5h8.44a.5.5 0 110 1H3.73a.5.5 0 01-.5-.5zm.51 3.48h8.44a.5.5 0 100-1.01H3.73a.5.5 0 100 1.01zm0 2.98h8.44a.5.5 0 000-1.02H3.73a.5.5 0 100 1.02zM9.8 12.3H3.73a.5.5 0 100 1.01H9.8a.5.5 0 100-1.01zm8.2 2.57a3.7 3.7 0 01-6.92 1.82h-8.2A2.88 2.88 0 010 13.83V2.87A2.88 2.88 0 012.87 0h10.15a2.88 2.88 0 012.88 2.87v8.68a3.7 3.7 0 012.1 3.33zm-7.31.8a3.68 3.68 0 014.2-4.45V2.87a1.86 1.86 0 00-1.87-1.86H2.87a1.86 1.86 0 00-1.86 1.86v10.96a1.86 1.86 0 001.86 1.86h7.82zm6.3-.8a2.69 2.69 0 10-5.38 0 2.69 2.69 0 005.38 0zm-1.4-.5h-.79v-.99a.5.5 0 00-1.01 0v1.49a.5.5 0 00.5.5h1.3a.5.5 0 100-1z" fill="#000"/>
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'
export default {
  name: 'RedemptionHistoryIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 18px;
  height: 19px;
}
</style>
