<template>
  <Icon class='icon' viewBox="0 0 22 15">
    <path d="M14.513 0a7.342 7.342 0 00-7.337 7.337 7.342 7.342 0 007.337 7.337 7.342 7.342 0 007.337-7.337A7.342 7.342 0 0014.513 0zm0 13.018a5.693 5.693 0 01-5.681-5.681 5.693 5.693 0 015.681-5.681c3.128 0 5.658 2.553 5.658 5.681s-2.53 5.681-5.658 5.681z" fill="#313C59"/>
    <path d="M17.135 6.509h-1.794V4.048a.825.825 0 00-.828-.828.825.825 0 00-.828.828v3.289c0 .46.368.828.828.828h2.622c.46 0 .828-.368.828-.828a.825.825 0 00-.828-.828zM5.658 2.806h-3.91a.825.825 0 00-.828.828c0 .46.368.828.828.828h3.91c.46 0 .828-.368.828-.828a.825.825 0 00-.828-.828zM5.658 10.212h-3.91a.825.825 0 00-.828.828c0 .46.368.828.828.828h3.91c.46 0 .828-.368.828-.828a.825.825 0 00-.828-.828zM5.566 7.337a.825.825 0 00-.828-.828H.828A.825.825 0 000 7.337c0 .46.368.828.828.828h3.91c.46 0 .828-.368.828-.828z" fill="#313C59"/>
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'
export default {
  name: 'UnpaidLateIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 22px;
  height: 15px;
}
</style>
