<template>
  <Icon :fill="color" class='icon'>
    <path d="M12.5 15.5a3 3 0 0 0 2.9-3.75l-3.65 3.65c.25.07.5.1.75.1Z" fill="#000"/>
    <path d="M22.42 12.25c-.9-1.57-2.17-2.9-3.65-3.88l-.72.73c1.35.85 2.5 2 3.37 3.4a10.43 10.43 0 0 1-5.77 4.5 5.5 5.5 0 0 0 1.67-7.15l-.75.75a4.51 4.51 0 0 1-5.97 5.97l-.63.63-.12.13-.68.67a11.4 11.4 0 0 0 13.25-5.25l.15-.25-.15-.25ZM7.15 17.15l-2.5 2.5.7.7 15-15-.68-.68-.02-.02L16.9 7.4a11 11 0 0 0-4.4-.9 11.5 11.5 0 0 0-9.93 5.75l-.15.25.15.25c1.08 1.9 2.7 3.4 4.58 4.4Zm5.35-5.9c-.7 0-1.25.55-1.25 1.25h-.5c0-.97.77-1.75 1.75-1.75v.5ZM15.3 9l-1.08 1.07a2.76 2.76 0 0 0-1.72-.57 3 3 0 0 0-2.45 4.72L8.97 15.3c-.62-.78-1-1.75-1-2.8A4.51 4.51 0 0 1 15.3 9ZM9.35 8a5.46 5.46 0 0 0-1.08 8.02l-.4.4a10.48 10.48 0 0 1-4.3-3.92A10.51 10.51 0 0 1 9.35 8Z" fill="#000"/>
  </Icon>
</template>

<script>

import Icon from './Icon.vue'

export default {
  name: 'GearIcon',
  components: {
    Icon
  },
  props: {
    color: {
      type: String,
      default: "#313C59"
    }
  }
}
</script>

<style scoped>
.icon {
  width: 25px;
  height: 25px;
}
</style>
