<template>
  <Icon fill="#606C8B" class='icon'>
    <path d="M8.938 17.24c4.552 0 8.256-3.704 8.256-8.256 0-4.553-3.704-8.257-8.256-8.257C4.385.727.68 4.43.68 8.984c0 4.552 3.704 8.255 8.257 8.255zm0-14.872a6.622 6.622 0 016.615 6.616 6.622 6.622 0 01-6.615 6.614 6.623 6.623 0 01-6.616-6.614 6.624 6.624 0 016.616-6.616z" fill="#606C8B"/><path d="M8.938 10.745a.82.82 0 00.82-.822V5.548a.821.821 0 00-1.64 0v4.375a.82.82 0 00.82.822zM8.938 13.239a.82.82 0 00.82-.82v-.137a.821.821 0 00-1.64 0v.136c0 .454.368.821.82.821z" />
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'

export default {
  name: 'AlertCircleIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 18px;
  height: 18px;
}
</style>
