<template>
  <Icon class='icon' viewBox="0 0 23 20">
    <path d="M11.9 0a.8.8 0 00-.8.8v4H.8a.8.8 0 00-.8.7v8a.8.8 0 00.8.8h10.3v4a.8.8 0 001.3.5l10.3-8.7a.8.8 0 000-1.2L12.4.2A.8.8 0 0012 0zm.8 2.5l8.3 7-8.3 7v-3a.8.8 0 00-.8-.8H1.6V6.3h10.3a.8.8 0 00.8-.8v-3z" fill="#000"/>
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'
export default {
  name: 'RedemptionHistoryIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 23px;
  height: 20px;
}
</style>
