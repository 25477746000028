export const kSortOptions = {
  alphabetical: {
    label: 'Alphabetical',
    value: 'alphabetical'
  },
  earnings: {
    label: 'Highest earnings',
    value: 'earnings'
  }
}

export const EModal = {
  welcome: 100
}
