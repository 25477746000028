<template>
  <Icon class='icon' viewBox="0 0 66 66">
    <path d="M16.21 0h-5.44a.79.79 0 100 1.58h3.62L6.65 9.32a.79.79 0 101.11 1.12l7.66-7.66v3.45a.79.79 0 101.58 0V.79a.79.79 0 00-.79-.79z" fill="#313C59"/>
    <path d="M13.48 7.95a.79.79 0 00-.79.79v6.22c0 .29-.24.53-.52.53H2.1a.53.53 0 01-.52-.53V4.9c0-.28.24-.52.52-.52h6.25a.79.79 0 100-1.58H2.1A2.1 2.1 0 000 4.9v10.06c0 1.16.94 2.1 2.1 2.1h10.07a2.1 2.1 0 002.1-2.1V8.74a.79.79 0 00-.79-.8z" fill="#313C59"/>
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'

export default {
  name: 'BigCheckIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 66px;
  height: 66px;
}
</style>
