<template>
  <Icon fill="#313C59" class='icon'>
    <path d="M16.7142 14.3567l-1.1784-1.1784L16.7142 12c1.3018-1.3018 1.3018-3.4124 0-4.7142s-3.4124-1.3018-4.7142 0l-1.1783 1.1784-1.1784-1.1784 1.1784-1.1783c1.9684-1.9367 5.1342-1.9109 7.0708.0576 1.9144 1.9458 1.9144 5.0674 0 7.0132l-1.1783 1.1784zm-2.3575 2.3575l-1.1784 1.1783c-1.9366 1.9685-5.1023 1.9942-7.0708.0576-1.9684-1.9367-1.9942-5.1024-.0576-7.0708a4.4043 4.4043 0 01.0576-.0576L7.286 9.6433l1.1783 1.1784L7.2858 12c-1.3017 1.3018-1.3017 3.4124 0 4.7142 1.3018 1.3018 3.4124 1.3018 4.7142 0l1.1783-1.1784 1.1784 1.1784zm0-8.25l1.1791 1.1791-5.8925 5.8917-1.1791-1.1783 5.8925-5.8917v-.0008z" />
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'

export default {
  name: 'ChainIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
}
</style>
