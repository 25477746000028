<template>
  <Icon fill="#606C8B" class='icon'>
    <path d="M4.94.94L1.89 4l3.05 3.06L4 8 0 4l4-4 .94.94z" />
  </Icon>
</template>

<script>

import Icon from 'components/icons/Icon.vue'

export default {
  name: 'RightChevronIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 8px;
  height: 8px;
}
</style>
